import React from 'react'
import { Link } from 'gatsby'
import { Location } from "@reach/router"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

function Projects() {
  const projects = [
    {
      title: 'Water Utility App',
      image: 'placeholder.png',
      description: 'Simple and straight forward water audit software.',
      // path: '/water-utility-app',
      path: '/info/#water-utility-app', // Temporary path
      website: 'https://www.waterutilityapp.com/'
    },
    {
      title: 'Boise Works',
      image: 'placeholder.png',
      description: 'Originally Boise Freelance, Boise Works makes it easy for businesses to hire employees in the Treasure Valley',
      // path: '/boise-works',
      path: '/info/#boise-works', // Temporary path
      website: 'https://boisefreelance.com/'
    },
    {
      title: 'PC Richards',
      image: 'placeholder.png',
      description: 'PC Richards',
      // path: '/pc-richards',
      path: '/info/#pc-richards', // Temporary path
      website: '#'
    },
    {
      title: 'Deep Mile',
      image: 'placeholder.png',
      description: 'Deep Mile',
      // path: '/deep-mile',
      path: '/info/#deep-mile', // Temporary path
      website: '#'
    },
  ]

  return (
    <Layout>
      <Location>
        {({ location }) =>
          <SEO
            title="Projects"
            pathname={location.pathname}
            keywords={[`gortek`, `app`, `startup`, `Boise Works`, `PC Richards`]}
          />
        }
      </Location>

      <h2 className="text-primary">Projects</h2>

      <section className="flex md:flex-row flex-col flex-wrap md:items-start items-center mt-8 w-full">
        {projects.map((project, index) => (
          <div
            key={index}
            className="md:w-1/3 w-full flex-shrink-0 p-3 py-6 hover:shadow-xl md:text-left text-center mb-6"
          >
            <Link to={`projects/${project.path}`}>
              <img className="mx-auto" alt={project.title} src={require(`../../images/${project.image}`)} />
              <h4 className="mt-4">{project.title}</h4>
            </Link>
            <p className="text-gray-600">{project.description}</p>
            <div className="underline mt-4">
              <a href={project.website} target="_blank" rel="noopener noreferrer">{project.website}</a>
            </div>
          </div>
        ))}
      </section>
    </Layout>
  )
}

export default Projects
